// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */)
}

